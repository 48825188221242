import React from "react";
import useSearchAllDocs from "../hooks/useSearchAllDocs";
import { Link } from "react-router-dom";

export default function TemplateNotice() {
  const { docs: templates, isLoading } = useSearchAllDocs("templates");

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Link to="/dashboard/templates">
      <div className="border-2 border-gray-200 h-36 w-36 flex flex-col items-center justify-center p-1  hover:bg-gray-100">
        {templates && (
          <div className="font-bold text-4xl text-khblue">
            {templates.length}
          </div>
        )}
        <div className="text-gray-600">Templates</div>
        {templates?.length < 1 && (
          <div className="text-xs text-center mt-2">
            Start creating your first template
          </div>
        )}
      </div>
    </Link>
  );
}
