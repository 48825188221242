import React from "react";
import InputBox from "../../components/InputBox";

import { useNavigate } from "react-router-dom";
import { resetpassword } from "../../Firebase/Firebase.utils";
import toast from "react-hot-toast";

function ResetPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");

  const handleResetPassword = (e) => {
    e.preventDefault();

    const reset = async () => {
      try {
        const res = await resetpassword(email);

        if (res) {
          toast.success("Check your email for password reset link");
          navigate("/login");
        } else {
          alert("Error: Please try again");
        }
      } catch (error) {}
    };
    reset();
  };

  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <div className="">
        <h1 className="text-3xl font-bold">Reset Password</h1>
        <p>
          Enter your email address and we will send you a link to reset your
          password.
        </p>
        <form action="" onSubmit={handleResetPassword}>
          <InputBox
            type="email"
            placeholder="Email Address"
            onChange={handleChange}
          />
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded mt-4 w-full"
            type="submit"
          >
            Send Reset Link
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
