import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { ImInsertTemplate } from "react-icons/im";
import { MdCampaign } from "react-icons/md";
import { CiBoxList } from "react-icons/ci";
import { MdContacts } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { GiArmorUpgrade } from "react-icons/gi";

function Sidebar() {
  const [activelink, SetActiveLink] = useState("dashboard");

  const links = [
    {
      label: "Dashboard",
      link: "/dashboard",
      icon: <MdDashboard />,
    },
    {
      label: "Templates",
      link: "templates",
      icon: <ImInsertTemplate />,
    },
    {
      label: "Campaigns",
      link: "campaigns",
      icon: <MdCampaign />,
    },
    {
      label: "List Management",
      link: "list-management",
      icon: <CiBoxList />,
    },
    // {
    //   label: "My Database",
    //   link: "my-database",
    //   icon: <MdContacts />,
    // },
    {
      label: "My Profile",
      link: "my-profile",
      icon: <CgProfile />,
    },
    {
      label: "Upgrade to Pro",
      link: "upgrade-to-pro",
      icon: <GiArmorUpgrade />,
    },
  ];

  const activeLinkStyle = "font-bold text-khOrange";
  const inactiveLinkStyle = "text-gray-600";

  const handleActiveLink = (link) => {
    SetActiveLink(link);
  };

  return (
    <div className="rounded-xl bg-white p-2 min-h-96 min-w-60">
      <div>
        <div className="mt-8 space-y-2">
          {links.map((item, index) => (
            <div key={index}>
              <Link to={`${item.link}`}>
                <div
                  className={`flex items-center gap-2  cursor-pointer ${
                    activelink === item.link
                      ? activeLinkStyle
                      : inactiveLinkStyle
                  }`}
                  onClick={() => {
                    handleActiveLink(item.link);
                  }}
                >
                  <div>{item.icon}</div>
                  <div>{item.label}</div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
