import React from "react";
import Logo from "../Logo";
import InputBox from "../../components/InputBox";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  createUserDoc,
  signupWithEmail,
  checkUser,
  signInWithEmail,
  findUserDocbyId,
} from "../../Firebase/Firebase.utils";
import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";

export default function LoginForm() {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    e.preventDefault();
    const signIn = async () => {
      try {
        const res = await signInWithEmail(formData.email, formData.password);

        if (res) {
          const { email, uid } = res.user;

          const { displayName, roles, department, accountStatus } =
            await findUserDocbyId(res.user.uid);

          dispatch(
            loadUser({
              email,
              displayName,
              uid,
              roles,
              department,
              accountStatus,
            })
          );
          navigate("/dashboard");
        }
        setIsProcessing(false);
      } catch (error) {
        setIsProcessing(false);
        setFormData({});
        alert(error.message);
      }
    };
    signIn();
  };

  return (
    <div className="w-[350px] min-h-[500px] bg-white p-2 rounded-xl text-blue-900 border border-gray-100">
      <div>
        <Logo />
      </div>
      <div className="font-semibold text-sm my-4">Log in to your account</div>
      <div>
        <form onSubmit={handleSubmit} className="">
          <div className="space-y-4">
            <InputBox
              label="Email Address"
              placeholder="Enter your email address"
              name="email"
              type="email"
              onChange={handleChange}
              required
            />
            <div className="relative">
              <InputBox
                label="Password"
                placeholder="Enter your password"
                name="password"
                onChange={handleChange}
                required
                type={showPassword ? "text" : "password"}
              />
              <button
                className="absolute top-0 right-0"
                onClick={() => setShowPassword(!showPassword)}
                type="button"
              >
                <IoEyeOutline />
              </button>
            </div>
          </div>
          <button
            className="bg-blue-900 mt-8 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-700"
            disabled={isProcessing}
            type="submit"
          >
            {isProcessing ? "Processing..." : "Log In"}
          </button>
        </form>

        <div className="text-sm mt-8">
          No Account Yet? Sign up{" "}
          <span className="underline">
            <Link to="/signup">here</Link>
          </span>
        </div>

        <div>
          <Link to="/forgot-password" className="text-sm underline">
            Forgot my password
          </Link>
        </div>
      </div>
    </div>
  );
}
