import React from "react";
import Logo from "../Home/Logo";
import { SignOutUser } from "../Firebase/Firebase.utils";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgProfile } from "react-icons/cg";
import { IoIosNotifications } from "react-icons/io";
import { IoLogOut } from "react-icons/io5";
import { Link } from "react-router-dom";
import KohaGoLogo from "../assets/logos/Logo.png";

function DashHeader({ navbarOpen, setNavbarOpen }) {
  const handleSignOut = (e) => {
    e.preventDefault();
    SignOutUser();
  };

  const handleNavbarOpen = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <div className="bg-blue-900 text-sm text-white flex items-center p-4 justify-between ">
      <div className="flex items-center justify-center gap-4">
        <button className="sm:hidden" onClick={handleNavbarOpen}>
          <GiHamburgerMenu />
        </button>
        <div className="font-bold text-sm bg-white text-khOrange p-1 rounded-xl ">
          <div className="flex items-center justify-normal gap-2">
            <img src={KohaGoLogo} className="w-12" alt="" />
            <div>Koha-Go</div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-4 text-xl">
        <button className="text-xl">
          <Link to="/dashboard/my-profile">
            <CgProfile />
          </Link>
        </button>
        <button className="text-xl">
          <IoIosNotifications />
        </button>
        <button className="text-xl" onClick={handleSignOut}>
          <IoLogOut />
        </button>
      </div>
    </div>
  );
}

export default DashHeader;
