import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Hero() {
  return (
    <div
      className="flex items-center justify-center gap-8 flex-wrap p-2"
      style={{
        backgroundImage: `url("")`,
      }}
    >
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
        className="w-[700px]"
      >
        <div className="font-semibold text-blue-900 text-5xl text-left">
          Start growing your business using verified and targeted emails
        </div>
        <div className="text-left  text-white mt-4 text-3xl">
          Use our platform as your stepping stone to success!
        </div>
        <div className="px-4 py-2 text-4xl bg-blue-900 text-white rounded-lg mt-8 w-fit cursor-pointer">
          <Link to="/signup">Sign up now</Link>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
        className="w-[400px]"
      >
        <img
          src={
            "https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=2426&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt="hero"
          className="w-96 h-96 rounded-lg"
        />
      </motion.div>
    </div>
  );
}

export default Hero;
