import React from "react";
import Logo from "../Logo";
import InputBox from "../../components/InputBox";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  createUserDoc,
  signupWithEmail,
  checkUser,
} from "../../Firebase/Firebase.utils";
import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import Modal from "../../components/Modal";
import TermsConditions from "./TermsConditions";
import TermsAndConditions from "./TermsConditions";

function SignupForm() {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkDomainEmail = (email) => {
    const domain = email.split("@")[1];
    if (domain === "leechiu.com") {
      return true;
    } else {
      return false;
    }
  };

  const checkPassword = (password, confirmPassword) => {
    if (password === confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  const checkPasswordFormat = (password) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
    if (regex.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const createUserDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;
    const userRef = await createUserDoc(userAuth, additionalData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const { displayName, email, password, confirm_password } = formData;

    // if (!email.endsWith("@leechiu.com")) {
    //   alert("Please use a Leechiu email address (@leechiu.com)");
    //   setIsProcessing(false);
    //   return;
    // }

    if (password !== confirm_password) {
      alert("Passwords do not match");
      setIsProcessing(false);
      return;
    }

    const passwordCheck = checkPasswordFormat(password);
    if (!passwordCheck) {
      alert(
        "Please choose a password of at least 8characters long which is a combination of upppercase, lowercase, numbers"
      );
      setIsProcessing(false);
      return;
    }

    // signUpUsingEmail(email, password);
    const signUp = async () => {
      //check if terms and conditions are checked
      if (!termsChecked) {
        toast.error("Please agree to the terms and conditions");
        setIsProcessing(false);
        return;
      }

      try {
        const res = await signupWithEmail(email, password);
        if (res) {
          const userCheck = await checkUser(res.user);

          if (!userCheck) {
            await createUserDoc(res.user, {
              displayName,
              accountStatus: "trial",
              credits: 20,
              termsAccepted: true,
            });
            dispatch(
              loadUser({
                email,
                displayName,
                accountStatus: "trial",
                credits: 20,
                uid: res.user.uid,
              })
            );
            navigate("/dashboard");
            setIsProcessing(false);
          } else {
            alert("User already exists, sign in instead");
            setIsProcessing(false);
          }
        }
      } catch (error) {
        setIsProcessing(false);
      }
    };
    signUp();
  };

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      setTermsChecked(true);
    } else {
      setTermsChecked(false);
    }
  };

  const handleShowTerms = () => {
    setShowTerms(!showTerms);
  };

  return (
    <div className="w-[350px] min-h-[500px] bg-white p-2 rounded-xl text-blue-900 border border-gray-100">
      <div>
        <Logo />
      </div>
      <div className="font-semibold text-sm my-4">
        Fill in your details below to get started
      </div>
      <div>
        <form onSubmit={handleSubmit} className="">
          <div className="space-y-4">
            <InputBox
              label="Full Name"
              placeholder="Enter your full name"
              name="displayName"
              onChange={handleChange}
              required
            />
            <InputBox
              label="Email Address"
              placeholder="Enter your email address"
              name="email"
              type="email"
              onChange={handleChange}
              required
            />
            <div className="relative">
              <InputBox
                label="Password"
                placeholder="Enter your password"
                name="password"
                onChange={handleChange}
                required
                type={showPassword ? "text" : "password"}
              />
              <button
                className="absolute top-0 right-0"
                onClick={() => setShowPassword(!showPassword)}
                type="button"
              >
                <IoEyeOutline />
              </button>
            </div>
            <InputBox
              label="Confirm your password"
              placeholder="Confirm your password"
              name="confirm_password"
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              required
            />
          </div>
          <button
            className="bg-blue-900 mt-8 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-700"
            disabled={isProcessing}
            type="submit"
          >
            {isProcessing ? "Processing..." : "Sign Up"}
          </button>
        </form>

        <div className="text-sm mt-2 text-center">
          Already have an account?{" "}
          <span className="underline">
            <Link to="/login">Log in</Link>
          </span>
        </div>
      </div>

      <div className="text-xs font-bold mt-8">Terms and Conditions</div>
      <div className="flex items-center text-xs gap-2">
        <input type="checkbox" name="terms" onChange={handleCheckBox} />
        <div className="underline cursor-pointer" onClick={handleShowTerms}>
          I agree to the terms and conditions and privacy policy of Koha-Go
        </div>
      </div>

      {showTerms && (
        <Modal>
          <div className="overflow-y-scroll max-h-[500px] relative">
            <TermsAndConditions handleShowTerms={handleShowTerms} />
            <div className="text-right">
              <button
                className="bg-blue-900 text-white"
                onClick={handleShowTerms}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default SignupForm;
