import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import { Outlet } from "react-router-dom";
import AboutUs from "./AboutUs";
import WhyKohaGo from "./WhyKohaGo";
import Footer from "./Footer";
import HowToUse from "./HowToUse";
import SignUpCallToAction from "./SignUpCallToAction";

function Home() {
  return (
    <div>
      <div className="bg-[#3d8b95] min-h-screen p-2 lg:p-0">
        <Header />
        <Hero />
        <AboutUs />
        <WhyKohaGo />
        <HowToUse />
        <SignUpCallToAction />
        <Footer />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default Home;
