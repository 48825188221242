import React from "react";

export default function Footer() {
  return (
    <div className="max-w-[1080px] mx-auto  mt-8 pb-8">
      <div className="bg-gray-200 p-8 rounded-xl">
        <div className="flex items-center justify-between lg:flex-row flex-col gap-2">
          <div className="flex flex-col">
            <div className="text-2xl font-bold">NewsLetter</div>
            <div>
              Be the first one to know about discounts, offers, updates and
              events.{" "}
            </div>
          </div>
          <div className="p-4 bg-khblue text-white">
            <div>Enter your email</div>
            <input
              type="text"
              className="border p-2 rounded-xl"
              placeholder="Enter your email"
            />
            <button className="bg-khOrange text-white px-4 py-2 rounded-lg">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
