import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";

function Header() {
  return (
    <div className="text-blue-900 border-b border-gray-200 flex flex-col lg:flex-row items-center justify-between px-8 py-4 max-w-[1080px] mx-auto ">
      <div className="">
        <Link to="/">
          <Logo />
        </Link>
      </div>

      <div className="flex items-center justify-normal gap-4 mt-4 lg:mt-0 ">
        <div className="cursor-pointer font-semibold hover:underline">
          <a href="/#aboutUs">About Us</a>
        </div>
        <div className="cursor-pointer font-semibold hover:underline">
          <a href="/#whyUs">Why Use Koha-Go</a>
        </div>
        <div className="cursor-pointer font-semibold hover:underline">
          <a href="/#howTo">How to Use</a>
        </div>
      </div>

      <div className="flex items-center justify-normal gap-4">
        <div className="bg-blue-900 text-white px-4 py-2 rounded-lg">
          <Link to="/signup">Signup</Link>
        </div>

        <div>
          <Link to="/login">Login</Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
