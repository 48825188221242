import React from "react";
import InputBox from "../components/InputBox";
import { addNewDoc } from "../Firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

function CreateNewTemplateForm({ handleAddMode }) {
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const docObject = {
      ...formData,
      createdBy: currentUser.uid,
      createdAt: new Date().toLocaleString(),
      updatedAt: new Date().toLocaleString(),
    };
    try {
      await addNewDoc("templates", docObject);
      setIsProcessing(false);
      handleAddMode();
      toast.success("Template created successfully");
    } catch (error) {
      setIsProcessing(false);
    }
  };

  return (
    <div className="relative space-y-4">
      <div>
        <button className="close-button" onClick={handleAddMode}>
          Cancel
        </button>
      </div>
      <div className="header">Create New Template</div>

      <div className="">
        <form className="space-y-4" onSubmit={handleSubmit}>
          <InputBox
            name="template_name"
            placeholder="Enter Template Name"
            label="Template Name"
            type="text"
            onChange={handleChange}
            required={true}
          />

          <InputBox
            name="description"
            placeholder="Enter Description"
            label="Description"
            type="text"
            onChange={handleChange}
          />

          <InputBox
            name="template_subject"
            placeholder="Enter Template Subject"
            label="Subject"
            type="text"
            onChange={handleChange}
            required={true}
          />

          <div className="w-full">
            <button className="blue-button w-full" type="submit">
              {isProcessing ? "Processing..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateNewTemplateForm;
