import React from 'react'
import { Link } from 'react-router-dom'

function SignUpCallToAction() {
  return (
    <div className='mt-8'>
        <div className="bg-khOrange text-white p-4 text-center">
            <h2 className="text-3xl font-bold">Ready to get started?</h2>
            <p className="text-5xl mt-4">Sign up now and start closing deals!!</p>
            <div className="bg-white text-khOrange p-2 rounded-lg mt-2 text-4xl w-fit mx-auto">
            <Link to="/signup">Sign up</Link>
            </div>
        </div>
    </div>
  )
}

export default SignUpCallToAction