import React from "react";
import { Link } from "react-router-dom";

export default function Cancelled() {
  return (
    <div className="bubble">
      <div>You have cancelled the upgrade session</div>

      <div className="text-khOrange mt-8">
        You are currently on a trial mode. Upgrade to pro to unlock all
        features. Click{" "}
        <Link className="underline" to="/dashboard/upgrade-to-pro">
          here
        </Link>{" "}
        to upgrade.
      </div>
    </div>
  );
}
