import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MonthlyCard from "./MonthlyCard";
import AnnualCard from "./AnnualCard";
import gcashlogo from "../assets/logos/gcash.jpg";
import bpiLogo from "../assets/logos/bpi.png";
import grabpayLogo from "../assets/logos/grabpay.png";
import unionbankLogo from "../assets/logos/unionbank.png";
import Logo from "../Home/Logo";
import { useSelector } from "react-redux";
import { addNewDoc, getDocData, setDocData } from "../Firebase/Firebase.utils";

export default function UpgradePro() {
  const currentUser = useSelector((state) => state.userObject);
  const publicKey = process.env.PUBLIC_KEY;

  const secretKey = process.env.SECRET_KEY;
  const basic = process.env.BASIC_KEY;
  const navigate = useNavigate();

  const [subscription, setSubscription] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [reference_number, setReferenceNumber] = React.useState("");
  const [checkoutSession, setCheckoutSession] = React.useState("");
  const [userData, setUserData] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [isProcessing, setIsProcessing] = React.useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const data = await getDocData("users", currentUser.uid);
      setUserData(data);
      setIsLoading(false);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    //create a reference number using uid - timestamp
    const date = new Date();
    const timestamp = date.getTime();
    const ref = currentUser.uid + "-" + timestamp;
    setReferenceNumber(ref);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (userData.accountStatus === "active") {
    return (
      <div>You are already a Pro user. Please proceed to your dashboard.</div>
    );
  }

  const subscriptions = [
    {
      name: "Monthly",
      price: 300,
      description: "Pay 300 monthly",
    },
    {
      name: "Annual",
      price: 2999,
      description: "Pay 2999 annually",
    },
  ];

  const paymentMethods = [
    {
      name: "GCash",
      description: "Pay using GCash",
      value: "gcash",
      Logo: gcashlogo,
    },
    {
      name: "BPI",
      description: "Pay using BPI online",
      value: "dob",
      Logo: bpiLogo,
    },
    {
      name: "Grab Pay",
      description: "Pay using Grab Pay",
      value: "grab_pay",
      Logo: grabpayLogo,
    },
    {
      name: "Unionbank Online",
      description: "Pay using Unionbank Online",
      value: "dob_ubp",
      Logo: unionbankLogo,
    },
  ];

  const handleSelectPaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePaymongoCheckout = async () => {
    setIsProcessing(true);
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Basic c2tfbGl2ZV9mNHlMVGM5bzh0dVduUno5MUNNVDE0UzQ6",
      },
      body: JSON.stringify({
        data: {
          attributes: {
            send_email_receipt: true,
            show_description: true,
            show_line_items: true,
            line_items: [
              {
                currency: "PHP",
                amount: subscription === "monthly" ? 30000 : 299900,
                // amount: 2000,
                description: `Subscription to KohaGo - ${subscription}`,
                name: `Subscription to KohaGo - ${subscription}`,
                quantity: 1,
              },
            ],
            payment_method_types: ["gcash", "dob", "grab_pay", "dob_ubp"],
            description: "subscription",
            reference_number: reference_number,
            cancel_url: "https://koha-go.com/dashboard/cancelled",
            success_url: `https://koha-go.com/dashboard/success/${currentUser.uid}`,
            statement_descriptor: "Subscription for KohaGo",
          },
        },
      }),
    };

    fetch("https://api.paymongo.com/v1/checkout_sessions", options)
      .then((response) => response.json())
      .then(async (response) => {
        await setDocData("transactions", currentUser.uid, {
          uid: currentUser.uid,
          reference_number: reference_number,
          subscription: subscription,
          status: "pending",
          amount: subscription === "monthly" ? 30000 : 299900,
          checkout_session_id: response.data.id,
          response: response,
        });

        await setDocData("sessions", response.data.id, response);
        window.location.href = response.data.attributes.checkout_url;
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="bubble">
      <div className="header">UpgradePro</div>
      <div>
        <div className="mt-8 header">Select from Choices below</div>
        <div className="flex items-center gap-4">
          <div className="">
            <MonthlyCard
              subscription={subscription}
              setSubscription={setSubscription}
            />
          </div>
          <div>
            <AnnualCard
              subscription={subscription}
              setSubscription={setSubscription}
            />
          </div>
        </div>
      </div>

      {/* <div>
        <div className="mt-8 header">Select Payment Method</div>
        <div className="flex items-start gap-4 flex-col ">
          {paymentMethods.map((method, index) => (
            <div
              key={index}
              className="flex items-center justify-normal gap-2 w- border border-gray-400 p-2 w-60"
            >
              <input
                type="radio"
                name="paymentMethod"
                value={method.value}
                id={method.value}
                onChange={handleSelectPaymentMethod}
              />
              <label htmlFor={method.value}>
                <div className="w-24">
                  <img src={method.Logo} alt={method.name} />
                </div>
              </label>
              <div>
                <div className="font-bold">{method.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      <div className="mt-8">
        <button
          className="bg-blue-900 text-white text-2xl"
          onClick={handlePaymongoCheckout}
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : "Proceed to Payment"}
        </button>
      </div>
    </div>
  );
}
