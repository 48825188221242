import React, { useEffect, useState } from "react";
import Modal from "../components/Modal";
import CreateNewTemplateForm from "./CreateNewTemplateForm";
import { getAllDocsSnapByUserId } from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Templates() {
  const currentUser = useSelector((state) => state.userObject);
  const [addMode, setAddMode] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getAllTemplates = async () => {
      const data = await getAllDocsSnapByUserId(
        "templates",
        currentUser.uid,
        (templates) => {
          setTemplates(templates);
          setIsLoading(false);
        }
      );
    };
    getAllTemplates();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleNavigate = (id) => {
    navigate(`/dashboard/template/${id}`);
  };

  return (
    <div>
      <div className="header">Templates</div>
      <div className="text-left">
        <button className="blue-button" onClick={handleAddMode}>
          Create a new template
        </button>
      </div>

      <div>
        {addMode && (
          <Modal>
            <CreateNewTemplateForm handleAddMode={handleAddMode} />
          </Modal>
        )}
      </div>

      <div>
        {templates.map((template) => (
          <div
            key={template.id}
            className="bubble mt-4 hover:bg-gray-200 cursor-pointer"
            onClick={() => handleNavigate(template.id)}
          >
            <div className="text-left font-bold">{template.template_name}</div>
            <div className="text-left">{template.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Templates;
