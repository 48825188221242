import React, { useEffect, useState } from "react";
import { queryAllDocsByField } from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";

export default function useSearchAllDocs(collection) {
  const [docs, setDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchDocs = async () => {
      const data = await queryAllDocsByField(
        collection,
        "createdBy",
        "==",
        currentUser.uid,
        (data) => {
          setDocs(data);
          setIsLoading(false);
        }
      );
    };
    fetchDocs();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return { docs, isLoading };
}
