import React, { useEffect, useState } from "react";
import InputBox from "../components/InputBox";
import TextArea from "../components/TextArea";
import {
  getDocDataSnap,
  setDocData,
  deleteDocById,
} from "../Firebase/Firebase.utils";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { storage } from "../Firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Tiptap from "./tiptap/Tiptap";

function TemplateId() {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [templateData, setTemplateData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [progress, setProgress] = useState(0);
  const [content, setContent] = useState("Edit your email content here");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTemplate = () => {
      getDocDataSnap("templates", id, (data) => {
        setTemplateData(data);
        setFormData(data);
        setContent(data.emailContent || "Edit your email content here");
        setIsLoading(false);
      });
    };
    fetchTemplate();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditMode = () => {
    setEditMode(!editMode);

    //check if email format is correct

    if (editMode) {
      setIsProcessing(true);
      const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailFormat.test(formData.replyTo)) {
        toast.error("Invalid email format");
        return;
      }
      const docObject = {
        ...formData,
        replyTo: formData.replyTo,
        template_subject: formData.template_subject,
        emailContent: content,
      };
      setDocData("templates", id, docObject)
        .then(() => {
          setIsProcessing(false);
          toast.success("Template updated successfully");
        })
        .catch((error) => {
          setIsProcessing(false);
          toast.error("Error updating template");
        });
    }
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `attachments/templates/${id}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
          default:
            break;
        }
      },
      (error) => {
        toast.error("Error uploading file");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setDocData("templates", id, {
            ...formData,
            attachments: downloadURL,
            filename: file.name,
          });
        });
      }
    );
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this template?")) {
      deleteDocById("templates", id)
        .then(() => {
          toast.success("Template deleted successfully");
          navigate("/dashboard/templates");
        })
        .catch((error) => {
          toast.error("Error deleting template");
        });
    }
  };

  return (
    <div className="space-y-8">
      <div className="header">Template Management</div>

      <div className="text-left flex items-center justify-between">
        <button className="blue-button" onClick={handleEditMode}>
          {editMode ? "Save" : "Edit"}
        </button>
        <button onClick={handleDelete}>Delete</button>
      </div>

      <div>
        <div className="text-gray-600 text-left text-sm">
          Template Name: {templateData.template_name}
        </div>
        <div className="text-gray-600 text-left text-sm">
          Description: {templateData.description}
        </div>
      </div>

      <div>
        <div className="bubble space-y-4">
          <InputBox
            name="emailName"
            type={"text"}
            placeholder="Name to Appear on Email"
            label="Email Name"
            onChange={handleChange}
            value={formData.emailName}
            disabled={!editMode}
          />

          <InputBox
            name="replyTo"
            type={"email"}
            placeholder="Reply to email address"
            label="Reply To"
            onChange={handleChange}
            value={formData.replyTo}
            disabled={!editMode}
          />

          <InputBox
            name="cc"
            type={"email"}
            placeholder="CC"
            label="CC"
            onChange={handleChange}
            value={formData.cc}
            disabled={!editMode}
          />

          <InputBox
            name="template_subject"
            placeholder="Input Email Subject"
            label="Email Subject"
            onChange={handleChange}
            value={formData.template_subject}
            disabled={!editMode}
          />

          {/* <TextArea
            name="emailContent"
            placeholder={"Type your email content here"}
            label="Email Content"
            onChange={handleChange}
            rows="6"
            value={formData.emailContent}
            disabled={!editMode}
          /> */}

          <div className="border border-gray-200 rounded-xl p-4 min-h-[500px]">
            <Tiptap
              content={content}
              setContent={setContent}
              editable={editMode}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="header">Attachments</div>

        {formData.attachments && (
          <div className="text-gray-600 font-bold text-left mb-8">
            <a href={formData.attachments} target="_blank">
              {formData.filename}
            </a>
          </div>
        )}

        <div className="text-left">
          <div className="text-left text-xs">Replace or Upload attachment</div>
          <input type="file" onChange={handleUpload} />
          {progress > 0 && (
            <div className="text-gray-600 text-sm">
              {progress.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
              % uploaded
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TemplateId;
