import React from "react";

export default function HowToUse() {
  const steps = [
    {
      title: "Step 1",
      content: "Sign up for an account on Koha-Go",
    },
    {
      title: "Step 2",
      content: "Create an email template",
    },
    {
      title: "Step 3",
      content: "Create a campaign for your offering",
    },
    {
      title: "Step 4",
      content: "Select from companies you want to reach out to",
    },
    {
      title: "Step 5",
      content: "Select Department and individuals you want to reach out to",
    },
    {
      title: "Step 6",
      content: "Run your campaign",
    },
  ];

  return (
    <div className="max-w-[1080px] mx-auto mt-8" id="howTo">
      <div className="font-bold text-3xl lg:text-4xl text-blue-900">
        How To Use Koha-Go
      </div>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className="bg-white p-4 rounded-lg border border-gray-200"
            >
              <div className="font-bold text-2xl">{step.title}</div>
              <div className="mt-4">{step.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
