import React from "react";
import DashHeader from "./DashHeader";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { motion } from "framer-motion";

function Dashboard() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [mobileMode, setMobileMode] = React.useState(false);

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth > 640) {
        setNavbarOpen(true);
        setMobileMode(false);
      } else {
        setNavbarOpen(false);
        setMobileMode(true);
      }
    };
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  return (
    <div className="overflow-x-clip">
      <div className="">
        <DashHeader setNavbarOpen={setNavbarOpen} navbarOpen={navbarOpen} />
      </div>
      <div className="relative flex items-start justify-center sm:gap-4 bg-khblue bg-opacity-50 min-h-screen min-w-screen p-2">
        <div
          className={
            mobileMode && navbarOpen
              ? "w-screen h-screen z-10 bg-black bg-opacity-50 absolute top-0 pt-2"
              : ""
          }
        >
          <motion.div
            initial={{ x: -300 }}
            animate={{ x: navbarOpen ? 0 : -300 }}
            transition={{ duration: 0.5 }}
            className="fixed left-2 sm:static"
          >
            <Sidebar />
          </motion.div>
        </div>
        <div className="w-[700px] p-2">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
