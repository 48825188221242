import React from "react";
import InputBox from "../components/InputBox";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getAllDocsSnapByUserId, addNewDoc } from "../Firebase/Firebase.utils";
import SelectBox from "../components/SelectBox";
import toast from "react-hot-toast";

function AddCampaignForm({ handleAddMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [templates, setTemplates] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);

  React.useEffect(() => {
    const getAllTemplates = async () => {
      const data = await getAllDocsSnapByUserId(
        "templates",
        currentUser.uid,
        (templates) => {
          setTemplates(templates);
          setIsLoading(false);
        }
      );
    };
    getAllTemplates();
  }, []);

  const options = templates.map((template) => ({
    value: template.id,
    label: template.template_name,
  }));

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = (selectedOption) => {
    setFormData({ ...formData, template: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const docObject = {
      ...formData,
      createdBy: currentUser.uid,
      createdAt: new Date().toLocaleString(),
      updatedAt: new Date().toLocaleString(),
    };

    setIsProcessing(true);
    try {
      await addNewDoc("campaigns", docObject);
      setIsProcessing(false);
      handleAddMode();
      toast.success("Campaign created successfully");
    } catch (error) {
      setIsProcessing(false);
    }
  };

  return (
    <div className="relative">
      <div className="header">Add Campaign</div>

      <div>
        <button className="close-button" onClick={handleAddMode}>
          Close
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="space-y-4 mt-4">
          <InputBox
            name="campaign_name"
            label="Campaign Name"
            type="text"
            placeholder="Enter Campaign Name"
            required={true}
            onChange={handleChange}
          />

          <SelectBox
            name="template"
            label="Select Template"
            options={options}
            placeholder="Select Template"
            onChange={handleSelect}
          />

          {/* <InputBox
            name="credits"
            label="Number of Credits to Use"
            type="number"
            placeholder="Enter Number of Credits"
            onChange={handleChange}
            required={true}
          /> */}
        </div>

        <div className="w-full mt-4">
          <button className="blue-button w-full">
            {isProcessing ? "Processing..." : "Create Campaign"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddCampaignForm;
