import React from "react";
import Header from "../Header";
import LoginForm from "./LoginForm";
import { motion } from "framer-motion";

export default function Login() {
  return (
    <div className="overflow-x-clip">
      <div className="bg-white">
        <Header />
      </div>

      <div
        className="min-h-screen min-w-screen "
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1504868584819-f8e8b4b6d7e3?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-white w-screen h-screen bg-opacity-50">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="flex items-center justify-center w-full h-full"
          >
            <LoginForm />
          </motion.div>
        </div>
      </div>
    </div>
  );
}
