import React from "react";
import {
  getDocDataSnap,
  getAllDocsSnap,
  setDocData,
  deleteDocById,
} from "../Firebase/Firebase.utils";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import SelectBox from "../components/SelectBox";
import DatabaseSelectionBox from "./DatabaseSelectionBox";
import useGetAllDocs from "../hooks/useGetAllDocs";
import useSearchAllDocs from "../hooks/useSearchAllDocs";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";

function CampaignDetails() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const { id } = useParams();
  const [campaignData, setCampaignData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [filter, setFilter] = useState("company");
  const [isProcessing, setIsProcessing] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [credits, setCredits] = useState(0);
  const [accountStatus, setAccountStatus] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [templates, setTemplates] = useState([]);

  // const { docs: companies, isLoading: loading } = useGetAllDocs();
  // const { docs: templates, isLoading: loadingTemplates } =
  //   useSearchAllDocs("templates");

  useEffect(() => {
    const fetchCampaign = () => {
      getDocDataSnap("users", currentUser.uid, (data) => {
        setAccountStatus(data.accountStatus);

        if (data.accountStatus === "trial") {
          setCredits(data.credits);
        }

        if (data.accountStatus === "active") {
          setCredits(1000);
        }

        setIsLoading(false);
      });
    };
    fetchCampaign();
  }, []);

  useEffect(() => {
    const fetchCampaign = () => {
      getDocDataSnap("campaigns", id, (data) => {
        setCampaignData(data);
        setFormData(data);
      });
    };
    fetchCampaign();
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      const data = await getAllDocsSnap("companies", (data) => {
        data.sort((a, b) => {
          if (a.company_name < b.company_name) {
            return -1;
          }
          if (a.company_name > b.company_name) {
            return 1;
          }
          return 0;
        });

        setCompanies(data);
      });
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
      const data = await getAllDocsSnap("templates", (data) => {
        setTemplates(data);
        setIsLoading(false);
      });
    };
    fetchTemplates();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const templateOptions = templates?.map((template) => ({
    label: template.template_name,
    value: template.id,
  }));

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectTemplate = async (selectedOption) => {
    toast.promise(
      setDocData("campaigns", id, {
        ...formData,
        template: selectedOption.value,
      }),
      {
        loading: "Saving...",
        success: "Saved!",
        error: "An error occurred",
      }
    );
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const targetAudience = [
    { label: "Company Level", value: "company" },
    {
      label: "HR Department",
      value: "hr",
    },

    {
      label: "Marketing Department",
      value: "marketing",
    },
    {
      label: "Sales Department",
      value: "sales",
    },
    {
      label: "Procurement Department",
      value: "procurement",
    },
    // {
    //   label: "Finance Department",
    //   value: "finance",
    // },
    // {
    //   label: "IT Department",
    //   value: "it",
    // },
    // {
    //   label: "Operations Department",
    //   value: "operations",
    // },
    // {
    //   label: "Customer Service Department",
    //   value: "customer_service",
    // },
    // {
    //   label: "Management",
    //   value: "management",
    // },
  ];

  const handleSelect = async (selectedOption) => {
    setFormData({ ...formData, target: selectedOption.value });
    setFilter(selectedOption.value);
    await setDocData("campaigns", id, {
      ...formData,
      target: selectedOption.value,
    });
    toast.success("Target Audience Updated");
  };

  const remainingCredits = credits - selectedCompanies.length;

  const { campaign_name } = campaignData;

  const filteredCompanies = companies?.filter((company) => {
    if (filter === "") {
      return company;
    }

    if (filter === "company") {
      return company.company_email_address !== undefined;
    }

    if (filter === "hr") {
      return (
        company.hr_manager_email !== undefined ||
        company.company_email_address !== undefined
      );
    }

    if (filter === "marketing") {
      return (
        company.company_email_address !== undefined ||
        company.marketing_managers_email !== undefined
      );
    }

    if (filter === "sales") {
      return (
        company.company_email_address !== undefined ||
        company.sales_managers_email !== undefined
      );
    }

    if (filter === "procurement") {
      return (
        company.company_email_address !== undefined ||
        company.procurement_businessdev_officers_email !== undefined
      );
    }

    if (filter === "finance") {
      return (
        company.company_email_address !== undefined ||
        company.finance_email !== undefined
      );
    }
  });

  //creating an array of companies based on the filter
  let companyArrays = [];
  companyArrays = companies?.filter((company) => {
    if (selectedCompanies.includes(company.id)) {
      return company;
    }
  });

  //creating an array of emails based on the filter
  let emailArray = [];
  switch (filter) {
    case "company":
      emailArray = companyArrays.map(
        (company) => company.company_email_address
      );
      break;
    case "hr":
      emailArray = companyArrays.map(
        (company) => company.hr_manager_email || company.company_email_address
      );
      break;
    case "marketing":
      emailArray = companyArrays.map(
        (company) =>
          company.marketing_managers_email || company.company_email_address
      );
      break;
    case "sales":
      emailArray = companyArrays.map(
        (company) =>
          company.sales_managers_email || company.company_email_address
      );
      break;
    case "procurement":
      emailArray = companyArrays.map(
        (company) =>
          company.procurement_businessdev_officers_email ||
          company.company_email_address
      );
      break;
    default:
      emailArray = companyArrays.map(
        (company) => company.company_email_address
      );
  }

  const processEmails = async (company) => {
    setIsProcessing(true);
    setError(null);

    const templateData = templates.find(
      (template) => template.id === campaignData.template
    );

    const docObject = {
      from: `${templateData.emailName} <marketing@emails.koha-go.com>`,
      campaign_id: id,
      campaign_name: campaignData.campaign_name,
      campaign_template: templateData,
      target: filter,
      company: company,
      selected_emails: company,
      user_id: currentUser.uid,
      user: currentUser.email,
      runDate: new Date().toLocaleString(),
      attachments: templateData.attachments
        ? [
            {
              path: templateData?.attachments,
              filename: templateData?.filename,
            },
          ]
        : [],
    };

    const fetchReq = async () => {
      const res = await fetch(
        "https://kohago-email-app.vercel.app/api/campaign1",
        {
          method: "POST",
          body: JSON.stringify(docObject),
        }
      );
    };

    await fetchReq();
  };

  const handleRunCampaign = async () => {
    //check if companies have been selected
    if (selectedCompanies.length === 0) {
      toast.error("Please select companies to run campaign");
      setError("Please select companies to run campaign");
      return;
    }

    //check if template has been selected
    if (formData.template === "") {
      toast.error("Please select a template to run campaign");
      setError("Please select a template to run campaign");
      return;
    }

    //check if emailName has been set in templateData
    const templateData = templates.find(
      (template) => template.id === campaignData.template
    );

    if (templateData.emailName === "") {
      toast.error("Please set email name in template to run campaign");
      setError("Please set email name in template to run campaign");
      return;
    }

    //check if email subject has been set in templateData
    if (templateData.template_subject === "") {
      toast.error("Please set email subject in template to run campaign");
      setError("Please set email subject in template to run campaign");
      return;
    }

    //check if email content has been set in templateData
    if (templateData.emailContent === "") {
      toast.error("Please set email content in template to run campaign");
      setError("Please set email content in template to run campaign");
      return;
    }

    //check if replyTo has been set in templateData
    if (
      templateData.replyTo === "" ||
      templateData.replyTo === undefined ||
      templateData.replyTo === null
    ) {
      toast.error("Please set replyTo in template to run campaign");
      setError("Please set replyTo in template to run campaign");
      return;
    }

    //check if cc has been set in templateData
    if (
      templateData.cc === "" ||
      templateData.cc === undefined ||
      templateData.cc === null
    ) {
      toast.error("Please set cc in template to run campaign");
      setError("Please set cc in template to run campaign");
      return;
    }

    //check if email format is correct
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      !emailFormat.test(templateData.replyTo) ||
      !emailFormat.test(templateData.cc)
    ) {
      toast.error("Invalid email format in either CC or replyTo in template");
      setError("Invalid email format in either CC or replyTo in template");
      return;
    }
    const joinedEmails = emailArray.join(",");
    const newEmailArray = joinedEmails
      .split(/[,;/]/)
      .map((email) => email.trim())
      .filter((email) => email);

    //process emails
    console.log(newEmailArray);
    for (const email of newEmailArray) {
      await processEmails(email);
      setStatus(
        `Processing ${newEmailArray.indexOf(email) + 1} of ${
          newEmailArray.length
        } emails`
      );
    }

    if (accountStatus === "trial") {
      const newCredits = credits - selectedCompanies.length;
      await setDocData("users", currentUser.uid, {
        credits: newCredits,
      });
    }

    setStatus("Email Campaign has been sent to selected companies");
    toast.success("Email Campaign has been sent to selected companies");
    setIsProcessing(false);
    setSelectedCompanies([]);
  };

  const templateData = templates.find(
    (template) => template.id === campaignData.template
  );

  const handleDelete = async () => {
    await deleteDocById("campaigns", id);
    toast.success("Campaign deleted successfully");
    navigate("/dashboard/campaigns");
  };

  // console.log(accountStatus);
  // console.log(credits);
  // console.log(remainingCredits);

  //randomize the companies if accountStatus is trial
  if (accountStatus === "trial") {
    companies.sort(() => Math.random() - 0.5);
  }
  const joinedEmails = emailArray.join(",");
  const newEmailArray = joinedEmails
    .split(/[,;/]/)
    .map((email) => email.trim())
    .filter((email) => email);

  const toggleSelectAll = () => {
    //check first if trial only
    if (accountStatus === "trial") {
      if (selectedCompanies.length > remainingCredits) {
        toast.error(`You can only select up to ${remainingCredits} companies.`);
        return;
      }
    }

    if (selectedCompanies.length === filteredCompanies.length) {
      setSelectedCompanies([]);
    } else {
      setSelectedCompanies(filteredCompanies.map((company) => company.id));
    }
  };

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <div className="header">Campaign Details</div>
        <button>Delete</button>
      </div>

      <div className="text-left text-gray-600 font-bold bubble mb-8">
        <div>Campaign Name: {campaign_name}</div>
        {accountStatus === "trial" ? (
          <div>Number of Credits: {remainingCredits}</div>
        ) : (
          <div>Number of Credits: Unlimited</div>
        )}
        <div>
          <button className="blue-button" onClick={handleRunCampaign}>
            Run Campaign
          </button>
        </div>
        {status && (
          <div className="mt-8 text-khOrange italic text-sm font-bold">
            Status: {status}
          </div>
        )}

        {error && (
          <div className="mt-8 italic text-sm text-red-500 font-bold">
            Error: {error}
          </div>
        )}
      </div>

      <div className="bubble mb-8">
        <SelectBox
          name="Template"
          label={"Select Template"}
          options={templateOptions}
          onChange={handleSelectTemplate}
          value={templateOptions.find(
            (option) => option.value === formData.template
          )}
        />
        <div className="mt-4 text-xs underline">
          <Link to={`/dashboard/template/${campaignData.template}`}>
            Edit Template
          </Link>
        </div>
      </div>

      <div className="bubble mb-8">
        <SelectBox
          name="target"
          label={"Target Audience"}
          options={targetAudience}
          onChange={handleSelect}
          value={targetAudience.find(
            (option) => option.value === formData.target
          )}
        />
      </div>

      <div>
        <div className="label text-left">Select companies below</div>

        <button onClick={toggleSelectAll}>Select All</button>

        <div>
          <DatabaseSelectionBox
            companies={filteredCompanies}
            credits={accountStatus === "trial" ? credits : 1000}
            selectedCompanies={selectedCompanies}
            setSelectedCompanies={setSelectedCompanies}
          />
        </div>
      </div>

      {isProcessing && (
        <Modal>
          <div className="flex flex-col items-start justify-center w-96 h-96">
            <div className="font-bold">
              Sending email. Do not close this page.
            </div>
            <p>{status}</p>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default CampaignDetails;
