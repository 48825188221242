import React from "react";
import Select from "react-select";

function SelectBox({ label, options, onChange, ...otherProps }) {
  return (
    <div className="text-left text-sm text-gray-600">
      <label className="uppercase font-bold text-xs text-gray-600">
        {label}
      </label>
      <Select options={options} onChange={onChange} {...otherProps} />
    </div>
  );
}

export default SelectBox;
