import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDocData, setDocData } from "../Firebase/Firebase.utils";

export default function Success() {
  const currentUser = useSelector((state) => state.userObject);
  const { id } = useParams();

  const [sessionId, setSessionId] = React.useState("");
  const [paymentStatus, setPaymentStatus] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [paidAt, setPaidAt] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [checkOutStatus, setCheckOutStatus] = React.useState("");
  const [subscription, setSubscription] = React.useState("");
  const [accountStatus, setAccountStatus] = React.useState("");

  useEffect(() => {
    const fetchAccountStatus = async () => {
      const data = await getDocData("users", currentUser.uid);
      setAccountStatus(data.accountStatus);
    };
    fetchAccountStatus();
  }, []);

  useEffect(() => {
    const fetchSession = async () => {
      const data = await getDocData("transactions", id);
      setSessionId(data.checkout_session_id);
      setSubscription(data.subscription);
    };
    fetchSession();
  }, []);

  useEffect(() => {
    const options = {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: "Basic c2tfbGl2ZV9mNHlMVGM5bzh0dVduUno5MUNNVDE0UzQ6",
      },
    };

    fetch(`https://api.paymongo.com/v1/checkout_sessions/${sessionId}`, options)
      .then((response) => response.json())
      .then((response) => {
        if (
          response.data.attributes.payment_intent.attributes.status ===
          "succeeded"
        ) {
          setPaymentStatus("Success");
          setAmount(response.data.attributes.payment_intent.attributes.amount);
          setPaymentMethod(response.data.attributes.payment_method_used);
          setPaidAt(response.data.attributes.paid_at);
        }
      })
      .catch((err) => console.error(err));
  }, [sessionId]);

  useEffect(() => {
    const updatePaymentStatus = async () => {
      if (paymentStatus === "Success") {
        await setDocData("payments", currentUser.uid, {
          uid: currentUser.uid,
          sessionId: sessionId,
          paymentStatus: paymentStatus,
          paymentMethod: paymentMethod,
          amount: amount,
          paidAt: paidAt,
        });

        await setDocData("users", currentUser.uid, {
          accountStatus: "active",
          subscription: subscription,
          subscriptionEnd:
            subscription === "monthly" ? paidAt + 2592000 : paidAt + 31536000,
        });
        setIsLoading(false);
      }
    };
    updatePaymentStatus();
  }, [paymentStatus]);

  if (accountStatus === "active") {
    return (
      <div>
        You are already a Pro User. No need to upgrade again.{" "}
        <Link to="/dashboard">Go back to Dashboard</Link>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        Please wait. Do not leave this page. We are still processing your
        payment.
      </div>
    );
  }

  return (
    <div className="bubble">
      <div>Congratulations - You are now a Pro User</div>
      <div>Payment Status: {paymentStatus}</div>
      <div>Subscription: {subscription}</div>
      <div>Payment Method: {paymentMethod}</div>
      <div>Amount: {amount / 100}</div>
      <div>Date: {new Date(paidAt * 1000).toLocaleString()}</div>
    </div>
  );
}
