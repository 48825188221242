import React from "react";
import { TiInputChecked } from "react-icons/ti";

export default function AnnualCard({ subscription, setSubscription }) {
  const features = [
    "Unlock all company data",
    "Unlimited emails per day",
    "Unlimited searches",
    "Unlimited Templates",
    "Unlimited Campaigns",
    "Company Sorting",
    "Rich Text + HTML Emailing",
  ];

  const activeSubscriptionStyle = "bg-khOrange text-white text-xl";
  const inactiveSubscriptionStyle = "bg-white text-khblue text-xl";

  return (
    <div
      className={
        subscription === "annual"
          ? "bg-khblue w-60 h-96 rounded-xl p-2 border-4 border-khOrange"
          : "bg-khblue w-60 h-96 rounded-xl p-2"
      }
    >
      <div className="bg-khblue w-60 h-96 rounded-xl p-2">
        <div className="flex items-center justify-between">
          <div className="text-white text-2xl">Php 2,999 / year</div>
          <div className="text-xs">save Php 601 / year</div>
        </div>
        <div className="mt-8">
          <div className="text-white mb-4">This subscription gets</div>
          {features.map((item, index) => (
            <div key={index} className="flex items-center gap-2 ml-4 text-sm">
              <div className="text-sm">
                <TiInputChecked className="text-white" />
              </div>
              <div className="text-white">{item}</div>
            </div>
          ))}
        </div>
        <div className="text-center mt-8">
          <button
            className={
              subscription === "annual"
                ? activeSubscriptionStyle
                : inactiveSubscriptionStyle
            }
            onClick={() => setSubscription("annual")}
          >
            {subscription === "annual" ? "Selected" : "Select"}
          </button>
        </div>
      </div>
    </div>
  );
}
