import React, { useEffect } from "react";
import { database } from "../assets/database.js";
import {
  getAllDocsSnap,
  getDocDataSnap,
  setDocData,
} from "../Firebase/Firebase.utils";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function ListManagement() {
  const currentUser = useSelector((state) => state.userObject);
  const [isProcessing, setIsProcessing] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [accountStatus, setAccountStatus] = useState("trial");

  useEffect(() => {
    const fetchAccountStatus = async () => {
      const data = await getDocDataSnap("users", currentUser.uid, (data) => {
        setAccountStatus(data.accountStatus);
      });
    };
    fetchAccountStatus();
  }, []);

  useEffect(() => {
    const getAllCompanies = async () => {
      const data = await getAllDocsSnap("companies", (companies) => {
        //arrange array in ascending order using company_name
        companies.sort((a, b) => {
          if (a.company_name < b.company_name) {
            return -1;
          }
          if (a.company_name > b.company_name) {
            return 1;
          }
          return 0;
        });
        setCompanies(companies);
        setIsLoading(false);
      });
    };
    getAllCompanies();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const filteredCompanies = companies.filter((company) =>
    company.company_name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const currentItems = filteredCompanies.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  //filter companies without company_name
  const companiesWithoutName = companies.filter(
    (company) => company.company_name === undefined
  );

  const totalPages = Math.ceil(filteredCompanies.length / itemsPerPage);

  const handleNext = () => {
    if (currentPage === totalPages) {
      return;
    }
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (currentPage === 1) {
      return;
    }
    setCurrentPage((prev) => prev - 1);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  //randomize the companies if accountStatus is trial
  if (accountStatus === "trial") {
    companies.sort(() => Math.random() - 0.5);
  }

  return (
    <div className="space-y-8">
      <div className="header">List Management</div>
      <div className="font-bold text-sm">KoHa-Go Database</div>

      <div className="space-x-2 text-left">
        <label>Search</label>
        <input
          type="search"
          placeholder="Search here"
          className="p-2 rounded-xl text-sm"
          onChange={handleChange}
          value={searchTerm}
        />
      </div>

      <div>
        Page {currentPage} of {totalPages}
      </div>

      <div className="space-y-2">
        {currentItems.map((company, index) => (
          <div key={index}>
            <Link to={`/dashboard/company/${company.id}`}>
              <div className="text-left p-2 text-sm text-gray-600 rounded-lg bg-white hover:bg-gray-200 cursor-pointer">
                {company.company_name}
              </div>
            </Link>
          </div>
        ))}
      </div>

      <div className="space-x-4">
        <button className="blue-button" onClick={handlePrevious}>
          Previous
        </button>
        <button className="blue-button" onClick={handleNext}>
          Next
        </button>
      </div>

      {accountStatus === "trial" && (
        <div className="text-khOrange">
          You are currently on a trial mode. Upgrade to pro to unlock all
          features. Click{" "}
          <Link className="underline" to="/dashboard/upgrade-to-pro">
            here
          </Link>{" "}
          to upgrade.
        </div>
      )}
    </div>
  );
}

export default ListManagement;
