import React from "react";
import { motion } from "framer-motion";

function Modal({ children }) {
  return (
    <div className="h-full w-full top-0 left-0 bg-black bg-opacity-50 flex items-center justify-center fixed z-20 ">
      <motion.div
        className="bg-white p-2 rounded-xl min-w-[350px] min-h-[500px] mx-auto"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Modal;
