import React, { useEffect } from "react";
import { getDocDataSnap } from "../Firebase/Firebase.utils";
import { Link } from "react-router-dom";

export default function CreditsNotice({ user }) {
  const [credits, setCredits] = React.useState(null);
  const [accountStatus, setAccountStatus] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchCredits = async () => {
      const data = await getDocDataSnap("users", user, (data) => {
        setCredits(data.credits);
        setAccountStatus(data.accountStatus);
        setIsLoading(false);
      });
    };
    fetchCredits();
  }, []);

  if (isLoading) {
    <div>Loading...</div>;
  }

  return (
    <Link to="/dashboard/upgrade-to-pro">
      <div className="border-2 border-gray-200 h-36 w-36 flex flex-col items-center justify-center p-1 hover:bg-gray-100">
        {credits && (
          <div className="font-bold text-4xl text-khblue">{credits}</div>
        )}
        <div className="text-gray-600">Credits</div>
        {accountStatus === "trial" && (
          <div className="text-xs text-center mt-2">
            Upgrade to Pro for unlimited campaigns
          </div>
        )}
      </div>
    </Link>
  );
}
