import React from "react";

function AboutUs() {
  return (
    <div className="p-2 max-w-[1080px] mx-auto" id="aboutUs">
      <div className="font-bold text-blue-900 mt-8 text-4xl">About Koha-Go</div>
      <div className="mt-8 text-lg px-4 space-y-4">
        <div className="text-white text-3xl font-bold">Made for Philippine Market</div>
        <div>
          <span className="text-blue-900 font-bold">Koha-Go </span>is an online
          platform and tool for new businesses. It allows business,
          entrepreneurs and professionals to offer their services using our
          database of verified email addresses and contact information of
          different companies, businesses and individuals in the Philippines.
        </div>
        <div>
          No need for making your own list. No need to do a gruelling research
          of different companies which is tedious and takes a lot of time. We
          have done that for you. We have gathered email addresses of top
          corporations, businesses and individuals that are intended to get
          proposals such as Procurement department, HR department, and Finance
          department.
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
