import React from "react";

function WhyKohaGo() {
  const cards = [
    {
      title: "Verified and Accurate Data",
      description:
        "Our list are meticulously curated and verified to ensure that you get the most accurate data.",
    },

    {
      title: "Targeted Email List",
      description:
        "Our list are targeted to specific departments and individuals that are intended to get proposals.",
    },

    {
      title: "Easy to Use",
      description:
        "Our platform is easy to use and navigate. You can easily search for the companies you want to reach out to.",
    },
  ];

  return (
    <div className="max-w-[1080px] mx-auto mt-8" id="whyUs">
      <div className="text-blue-900 text-4xl font-bold">Why Use Koha-Go</div>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
          {cards.map((card, index) => (
            <div
              key={index}
              className="bg-white p-4 rounded-lg border border-gray-200"
            >
              <div className="font-bold text-2xl">{card.title}</div>
              <div className="mt-4">{card.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WhyKohaGo;
