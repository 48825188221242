import React from "react";

function InputBox({
  label,
  type,
  placeholder,
  value,
  onChange,
  ...otherProps
}) {
  return (
    <div className="flex flex-col items-start justify-normal gap-2">
      <label className="uppercase font-bold text-xs text-gray-600">
        {label}
      </label>
      <input
        className="border p-2 rounded-lg w-full text-sm"
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        {...otherProps}
      />
    </div>
  );
}

export default InputBox;
