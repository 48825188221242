import React from "react";

import KohaGoLogo from "../assets/logos/Logo.png";

function Logo() {
  return (
    <div className="font-bold text-3xl text-khOrange bg-white rounded-xl">
      <div className="flex items-center gap-2">
        <img className="w-24" src={KohaGoLogo} alt="" />
        <div>Koha-Go</div>
      </div>
    </div>
  );
}

export default Logo;
