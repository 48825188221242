import React from "react";
import { useState, useEffect } from "react";
import { getAllDocsSnap } from "../Firebase/Firebase.utils";
import toast from "react-hot-toast";

function DatabaseSelectionBox({
  credits,
  selectedCompanies,
  setSelectedCompanies,
  companies,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [creditCount, setCreditCount] = useState(credits);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredCompanies = companies.filter((company) =>
    company.company_name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const currentItems = filteredCompanies.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(companies.length / itemsPerPage);

  const handleNext = () => {
    if (currentPage === totalPages) {
      return;
    }
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (currentPage === 1) {
      return;
    }
    setCurrentPage((prev) => prev - 1);
  };

  const handleCheckbox = (e, selected) => {
    if (e.target.checked) {
      // Check if adding this company exceeds the credit count
      if (selectedCompanies.length < creditCount) {
        setSelectedCompanies([...selectedCompanies, selected]);
      } else {
        // Optionally, you can alert the user or provide feedback here
        toast.error(`You can only select up to ${creditCount} companies.`);
        e.target.checked = false; // Uncheck the checkbox if credit count exceeded
      }
    } else {
      setSelectedCompanies(
        selectedCompanies.filter((company) => company !== selected)
      );
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      {/* <div className="text-left">Total Companies: {companies.length}</div> */}
      <div className="label text-left mt-4">Search</div>
      <input
        className="border border-gray-300 rounded-md p-2 w-full"
        type="search"
        placeholder="Search Here"
        onChange={handleSearch}
      />

      <div>
        {currentItems.map((company, index) => (
          <div key={index} className="bubble mt-4 flex items-center gap-2">
            <input
              type="checkbox"
              onChange={(e) => handleCheckbox(e, company.id)} // Pass the company
              checked={selectedCompanies.includes(company.id)} // Check if the checkbox should be checked
            />
            <div className="text-left font-bold">{company.company_name}</div>
          </div>
        ))}
      </div>

      <div className="space-x-4 mt-8">
        <button className="blue-button" onClick={handlePrevious}>
          Previous
        </button>
        <button className="blue-button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
}

export default DatabaseSelectionBox;
