import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getDocDataSnap } from "../Firebase/Firebase.utils";
import TemplateNotice from "./TemplateNotice";
import CampaignsNotice from "./CampaignsNotice";
import { Link } from "react-router-dom";
import CreditsNotice from "./CreditsNotice";

function MainDashBoard() {
  const currentUser = useSelector((state) => state.userObject);
  const [accountStatus, setAccountStatus] = React.useState("trial");
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchAccountStatus = async () => {
      const data = await getDocDataSnap("users", currentUser.uid, (data) => {
        setAccountStatus(data.accountStatus);
        setIsLoading(false);
      });
    };
    fetchAccountStatus();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const steps = [
    {
      title: "Step 1",
      content: "Create an email template",
      link: "/dashboard/templates",
    },
    {
      title: "Step 2",
      content: "Create a campaign for your offering",
      link: "/dashboard/campaigns",
    },
    {
      title: "Step 3",
      content: "Select from companies you want to reach out to",
      link: "/dashboard/campaigns",
    },
    {
      title: "Step 4",
      content: "Select Department and individuals you want to reach out to",
      link: "/dashboard/campaigns",
    },
    {
      title: "Step 5",
      content: "Run your campaign",
      link: "/dashboard/campaigns",
    },
  ];

  return (
    <div className="w-full bg-white rounded-xl p-2 space-y-8">
      <div>
        <div className="header">Welcome {currentUser.displayName}</div>
        <div className="mt-8 text-khOrange font-bold">
          Welcome to Koha-Go, the simplest email campaign platform.{" "}
        </div>
        <div className="mt-8 text-gray-600">You currently have:</div>
        <div className="flex items-center gap-2">
          <TemplateNotice />
          <CampaignsNotice />
          {accountStatus === "trial" && (
            <div>
              <CreditsNotice user={currentUser.uid} />
            </div>
          )}
        </div>
      </div>
      {accountStatus === "trial" && (
        <div className="text-khOrange">
          You are currently on a trial mode. Upgrade to pro to unlock all
          features. Click{" "}
          <Link className="underline" to="/dashboard/upgrade-to-pro">
            here
          </Link>{" "}
          to upgrade.
        </div>
      )}

      <div>
        <div className="header">How to use</div>
        {
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
            {steps.map((step, index) => (
              <Link to={step.link} key={index}>
                <div
                  className="bg-white p-4 rounded-lg border border-gray-200 hover:bg-gray-200 cursor-pointer"
                  key={index}
                >
                  <div className="font-bold text-2xl">{step.title}</div>
                  <div className="mt-4">{step.content}</div>
                </div>
              </Link>
            ))}
          </div>
        }
      </div>

      <div>
        <div className="header">
          <Link to="/dashboard/list-management">List Management</Link>
        </div>
        <div className="text-gray-600">
          You can also view the details of each company in the database. Click
          under List Management to view the details.
        </div>
      </div>
    </div>
  );
}

export default MainDashBoard;
