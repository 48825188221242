import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getDocDataSnap, setDocData } from "../Firebase/Firebase.utils";
import { useState } from "react";
import toast from "react-hot-toast";

export default function MyProfile() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [accountStatus, setAccountStatus] = useState(false);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getUser = async () => {
      const data = await getDocDataSnap("users", currentUser.uid, (data) => {
        setAccountStatus(data.accountStatus);
        setUser(data);
        setFormData(data);
        setLoading(false);
      });
    };
    getUser();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditMode = async () => {
    setEditMode(!editMode);

    if (editMode) {
      await setDocData("users", currentUser.uid, formData);
      toast.success("Profile updated successfully");
    }
  };

  return (
    <div className="bubble">
      <div className="header">MyProfile</div>

      <div>
        <button className="blue-button" onClick={handleEditMode}>
          {editMode ? "Save" : "Edit Profile"}
        </button>
      </div>

      <div className="font-bold uppercase text-xs mt-8">
        Account Status: {accountStatus === "trial" ? "Trial" : "Pro User"}
      </div>
      <div className="space-y-2">
        {/* //fields */}
        <div className="flex items-center justify-between">
          <div className="font-bold text-khblue">Name</div>
          <div>
            <input
              className="border p-2 rounded-lg w-full text-sm"
              disabled={!editMode}
              type="text"
              value={formData.displayName}
              name="displayName"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="font-bold text-khblue">Email Address</div>
          <div>
            <input
              className="border p-2 rounded-lg w-full text-sm"
              disabled={true}
              type="text"
              value={formData.email}
              name="email"
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="font-bold text-khblue">Company Name</div>
          <div>
            <input
              className="border p-2 rounded-lg w-full text-sm"
              disabled={!editMode}
              type="text"
              value={formData.companyName}
              placeholder="Company Name"
              name="companyName"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="font-bold text-khblue">
            Short Descripton of your service or product
          </div>
          <div>
            <input
              className="border p-2 rounded-lg w-full text-sm"
              disabled={!editMode}
              type="text"
              value={formData.product}
              placeholder="Product Description"
              name="product"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="text-gray-600 text-xs mt-8">
        For any help or concerns please contact sales@infinity8data.com
      </div>
    </div>
  );
}
