import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import Signup from "./Home/Signup/Signup";
import Dashboard from "./Dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { auth } from "./Firebase/Firebase.utils";
import { loadUser } from "./store/userSlice";
import Login from "./Home/Login/Login";
import ResetPassword from "./Home/Login/ResetPassword";
import MainDashBoard from "./Dashboard/MainDashBoard";
import AboutUs from "./Home/AboutUs";
import Hero from "./Home/Hero";
import NotExisting from "./Dashboard/NotExisting";
import Campaigns from "./Dashboard/Campaigns";
import ListManagement from "./Dashboard/ListManagement";
import Company from "./Dashboard/Company";
import Templates from "./Dashboard/Templates";
import TemplateId from "./Dashboard/TemplateId";
import CampaignDetails from "./Dashboard/CampaignDetails";
import UpgradePro from "./Dashboard/UpgradePro";
import Success from "./Dashboard/Success";
import Cancelled from "./Dashboard/Cancelled";
import MyProfile from "./Dashboard/MyProfile";

function App() {
  const currentUser = useSelector((state) => state.userObject);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        dispatch(loadUser({}));
      }
    });
    return () => unsubscribe();
  }, []);

  if (
    currentUser === null ||
    !currentUser.email ||
    currentUser.email === null ||
    currentUser.email === "" ||
    currentUser.email === undefined
  ) {
    return (
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ResetPassword />} />☻
          <Route path="*" element={<Login />} />
        </Routes>
      </div>
    );
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<MainDashBoard />} />
          <Route path="campaigns" element={<Campaigns />} />
          <Route path="campaign/:id" element={<CampaignDetails />} />
          <Route path="company/:id" element={<Company />} />
          <Route path="list-management" element={<ListManagement />} />
          <Route path="my-profile" element={<MyProfile />} />
          <Route path="success/:id" element={<Success />} />
          <Route path="cancelled" element={<Cancelled />} />
          <Route path="templates" element={<Templates />} />
          <Route path="template/:id" element={<TemplateId />} />
          <Route path="upgrade-to-pro" element={<UpgradePro />} />

          <Route path="*" element={<NotExisting />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
