import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getDocData,
  getDocDataSnap,
  setDocData,
} from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";

function Company() {
  const { id } = useParams();
  const currentUser = useSelector((state) => state.userObject);
  const [company, setCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [accountStatus, setAccountStatus] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const data = await getDocDataSnap("users", currentUser.uid, (data) => {
        setAccountStatus(data.accountStatus);
      });
    };
    getUser();
  }, []);

  useEffect(() => {
    const getCompany = async () => {
      const data = await getDocData("companies", id);
      setCompany(data);
      setFormData(data);
      setIsLoading(false);
    };
    getCompany();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //stringify the object to see the data
  const database = JSON.stringify(company);

  //get all the keys of the object
  let keys = [];
  for (let key in company) {
    keys.push(key);
  }

  const handleEditMode = async () => {
    setEditMode(!editMode);

    if (editMode) {
      await setDocData("companies", id, formData);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isTrial = accountStatus === "trial";

  return (
    <div className="space-y-4">
      <div className="header">{company.company_name}</div>
      <div className="text-xs text-left">
        <div>{company.company_address}</div>
        <div>{company.company_website_link}</div>
        <div className={isTrial ? "blurred" : ""}>
          {company.company_email_address}
        </div>
      </div>
      <div className="text-left">
        {/* <button className="blue-button" onClick={handleEditMode}>
          {editMode ? "Save" : "Edit"}
        </button> */}
      </div>
      <div className="text-left font-bold text-gray-600 mt-4 text-sm uppercase">
        Contact Information
      </div>
      <div className={`bubble ${isTrial ? "blurred" : ""}`}>
        <table className="text-sm px-2 py-1 text-gray-600 text-left">
          <tr>
            <th className="">
              <div className="text-sm font-bold w-60">Data</div>
            </th>
            <th>
              <div className="text-sm font-bold w-60"> Information</div>
            </th>
          </tr>
          <tr>
            <td className="">Company Affiliations</td>
            <td>
              {editMode ? (
                <input
                  type="text"
                  name="company_affiliations"
                  value={formData.company_affiliations}
                  disabled={!editMode}
                  onChange={handleChange}
                  className="px-2 py-1 bg-gray-200 border border-gray-300 rounded-lg w-full"
                />
              ) : (
                <div>{formData.company_affiliations}</div>
              )}
            </td>
          </tr>
          <tr>
            <td className="">Company Email</td>
            <td>
              {editMode ? (
                <input
                  type="text"
                  name="company_email_address"
                  value={formData.company_email_address}
                  disabled={!editMode}
                  onChange={handleChange}
                  className="px-2 py-1 bg-gray-200 border border-gray-300 rounded-lg w-full"
                />
              ) : (
                <div>{formData.company_email_address}</div>
              )}
            </td>
          </tr>
          <tr>
            <td className="">Telephone Number</td>
            <td>
              {editMode ? (
                <textarea
                  type="text"
                  name="telephone_number"
                  value={formData.telephone_number}
                  disabled={!editMode}
                  onChange={handleChange}
                  rows={3}
                  className="bg-gray-200 rounded-lg px-2 py-1 w-full disabled:border-none disabled:resize-none disabled:overflow-hidden"
                />
              ) : (
                <div>{formData.telephone_number}</div>
              )}
            </td>
          </tr>
          <tr>
            <td className="">Human Resource Manager</td>
            <td>
              {editMode ? (
                <textarea
                  type="text"
                  name="hr_manager"
                  value={formData.hr_manager}
                  disabled={!editMode}
                  onChange={handleChange}
                  rows={3}
                  className="bg-gray-200 rounded-lg px-2 py-1 w-full disabled:border-none disabled:resize-none disabled:overflow-hidden"
                />
              ) : (
                <div>{formData.hr_manager}</div>
              )}
            </td>
          </tr>
          <tr>
            <td className="">Human Resource Email Address</td>
            <td>
              {editMode ? (
                <input
                  type="text"
                  name="hr_manager_email"
                  value={formData.hr_manager_email}
                  disabled={!editMode}
                  onChange={handleChange}
                  className="px-2 py-1 bg-gray-200 border border-gray-300 rounded-lg w-full"
                />
              ) : (
                <div>{formData.hr_manager_email}</div>
              )}
            </td>
          </tr>
          <tr>
            <td className="">Human Resource Contact Numbers</td>
            <td>
              {editMode ? (
                <input
                  type="text"
                  name="hr_managers_contact_numbers"
                  value={formData.hr_managers_contact_numbers}
                  disabled={!editMode}
                  onChange={handleChange}
                  className="px-2 py-1 bg-gray-200 border border-gray-300 rounded-lg w-full"
                />
              ) : (
                <div>{company.hr_managers_contact_numbers}</div>
              )}
            </td>
          </tr>
          <tr>
            <td className="">Procurement</td>
            <td>{company.procurement_businessdev_officers}</td>
          </tr>
          <tr>
            <td className="">Procurement Email</td>
            <td>{company.procurement_businessdev_officers}</td>
          </tr>

          <tr>
            <td className="">Company LinkedIn</td>
            <td>
              <a href={company.company_linkedin} target="_blank">
                {company.company_linkedin}
              </a>
            </td>
          </tr>

          <tr>
            <td className="">Facebook</td>
            <td>
              <a href={company.company_facebook} target="_blank">
                {company.company_facebook}
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div>
        <div className="text-left font-bold text-gray-600 mt-4 text-sm uppercase">
          Officers
        </div>
        <div className={`bubble ${isTrial ? "blurred" : ""}`}>
          <table className="text-sm px-2 py-1 text-gray-600 text-left">
            <tr>
              <th className="">
                <div className="text-sm font-bold w-60">Position</div>
              </th>
              <th>
                <div className="text-sm font-bold w-60"> Names</div>
              </th>
            </tr>
            <tr>
              <td className="">Chairman</td>
              <td>{company.company_chairman}</td>
            </tr>
            <tr>
              <td className="">President</td>
              <td>{company.name_of_president}</td>
            </tr>
            <tr>
              <td className="">Chief Executive Officer</td>
              <td>{company.name_of_ceo}</td>
            </tr>
            <tr>
              <td className="">Chief Finance Officer</td>
              <td>{company.name_of_cfo}</td>
            </tr>
            <tr>
              <td className="">Marketing Manager</td>
              <td>{company.marketing_managers}</td>
            </tr>
            <tr>
              <td className="">Company Directors</td>
              <td>{company.company_directors}</td>
            </tr>
          </table>
        </div>
      </div>

      {accountStatus === "trial" && (
        <div className="text-khOrange">
          You are currently on a trial mode. Upgrade to pro to unlock all
          features. Click{" "}
          <Link className="underline" to="/dashboard/upgrade-to-pro">
            here
          </Link>{" "}
          to upgrade.
        </div>
      )}
      {/* //ojbect keys
      <div className="text-left mt-8 text-sm text-gray-600">
        <table>
          {keys.map((key, index) => {
            return (
              <tr>
                <td className="font-bold px-2">{key}</td>
                <td className="px-2">{company[key]}</td>
              </tr>
            );
          })}
        </table>
      </div> */}
    </div>
  );
}

export default Company;
