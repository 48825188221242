import React, { useEffect, useState } from "react";
import Modal from "../components/Modal";
import AddCampaignForm from "./AddCampaignForm";
import { getAllDocsSnapByUserId } from "../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Campaigns() {
  const [addMode, setAddMode] = useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getAllCampaigns = async () => {
      const data = await getAllDocsSnapByUserId(
        "campaigns",
        currentUser.uid,
        (campaigns) => {
          setCampaigns(campaigns);
          setIsLoading(false);
        }
      );
    };
    getAllCampaigns();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  const handleAddMode = () => {
    setAddMode(!addMode);
  };
  return (
    <div>
      <div className="header">Campaigns</div>
      <div className="text-left mt-4">
        <button className="blue-button" onClick={handleAddMode}>
          Create new campaign
        </button>
      </div>

      <div>
        {addMode && (
          <Modal>
            <AddCampaignForm handleAddMode={handleAddMode} />
          </Modal>
        )}
      </div>

      <div>
        {campaigns.map((campaign) => (
          <div key={campaign.id} className="bubble mt-4">
            <Link to={`/dashboard/campaign/${campaign.id}`}>
              <div className="text-left font-bold">
                {campaign.campaign_name}
              </div>
              <div className="text-left">{campaign.description}</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Campaigns;
